<template>
  <div>
    <nav-bar v-if="showBackBtn" @onBack="handleBack" />
    <div v-if="isClose" class="category-task">
      <h3 class="task-name">{{ skuTaskName }}</h3>
      <div class="task-end-time">{{ effectiveTime }}</div>
      <div class="skuTaskUpload">
        <div class="skuTaskUploadFile">
          <span class="toolTip">{{ tips }}</span>
          <div class="category">
            <table border cellpadding="8">
              <tr>
                <th>顺序</th>
                <th>分类名称</th>
                <th>操作</th>
              </tr>
              <tr v-for="(item, index) in categories" :key="index">
                <td class="order">{{ index + 1 }}</td>
                <td class="cate-input">
                  <input v-model="item.categoryName" type="text" />
                </td>
                <td class="operation">
                  <div class="operation-box">
                    <van-button
                      type="danger"
                      size="mini"
                      @click="addCate(index)"
                      >新增</van-button
                    >
                    <van-button
                      type="default"
                      size="mini"
                      @click="delCate(index)"
                      >删除</van-button
                    >
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="submit">
        <van-button
          class="skuTaskSubmit"
          type="danger"
          :loading="loading"
          loading-text="提交中..."
          @click="handleSubmit"
        >
          点我提交!
        </van-button>
        <div class="feedback">如无法完成任务，请<span class="linkText" @click="clickFeedback">点击此处</span>反馈</div>
      </div>
    </div>
    <van-empty v-else description />
    <van-dialog
      v-model="showConfirm"
      title="请您再次确认提交的商品分类信息"
      show-cancel-button
      @confirm="confirmSubmit"
    >
      <div class="dialog-content-table">
        <table border cellpadding="8">
          <tr>
            <th>顺序</th>
            <th>分类名称</th>
          </tr>
          <tr v-for="(item, index) in categories" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.categoryName }}</td>
          </tr>
        </table>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import NavBar from "../components/nav-bar.vue";

import {
  getTaskDetails,
  listSkuCategoriesPreviousDay,
  insertSkuCategoryTask,
} from "@/apis";
import { formatDate } from "@/tools/date";
import { getUrlParams, userAccessValid } from "../shared";
import { Toast, Dialog } from "vant";
import backListMixins from "../mixins/back-list";

const COMPANY_ID = 81;
const DATA_TYPE = 5;
export default {
  name: "CategoryTask",
  components: {
    NavBar,
  },
  mixins: [backListMixins],
  data() {
    return {
      tips: "请从左至右按顺序输入小程序中的商品分类",
      urlParams: {},
      skuTaskName: "",
      effectiveTime: "",
      loading: false,
      companyId: COMPANY_ID,
      isClose: true,
      taskEndTime: 0,
      categories: [{ categoryName: "" }],
      showConfirm: false,
    };
  },
  async created() {
    this.urlParams = getUrlParams(this.$route.query);
    await this.getUserAccessValid();
  },
  methods: {
    // h5鉴权
    async getUserAccessValid() {
      try {
        const isValid = await userAccessValid(this.urlParams);
        this.isClose = isValid;
        if (isValid) {
          await this.getJobDetails();
          this.getlistSkuCategoriesPreviousDay();
        }
      } catch {
        Toast.fail("鉴权失败");
      }
    },
    // 预加载前一天基线
    async getlistSkuCategoriesPreviousDay() {
      try {
        const res = await listSkuCategoriesPreviousDay({
          cityId: this.urlParams.cityId,
          companyId: this.companyId,
        });
        if (res && res.data && res.data.length) {
          this.categories = [...res.data];
          this.tips = "请核对小程序中的商品分类";
        }
      } catch {
        Toast.fail("预加载品类异常！");
      }
    },
    // 获取任务详情
    async getJobDetails() {
      try {
        const { userTaskId, status } = this.urlParams;
        let res = await getTaskDetails({ userTaskId, status: status ? +status : undefined });
        if (res) {
          let data = res.data || {};
          if (res.code !== 0)
            return Toast.fail(res.msg || "获取任务详情接口返回错误");
          let time = formatDate(
            new Date(data.jobEndTime),
            "yyyy-MM-dd HH:mm:ss"
          );
          this.skuTaskName = data.name;
          this.effectiveTime = `提交截止时间:${time}`;
        }
      } catch (err) {
        Toast.fail(err.message || "获取任务详情接口请求错误");
      }
    },
    handleSubmit() {
      if (this.categories.length < 1) {
        Toast("请填写品类信息，再提交！");
        return;
      }
      if (this.categories.some((item) => !item.categoryName)) {
        Toast("请完整填写品类信息，再提交！");
        return;
      }
      this.showConfirm = true;
    },
    async confirmSubmit() {
      this.loading = true;
      const skuCategoryList = this.categories.map((item, index) => {
        item.order = index + 1;
        return item;
      });
      const { jobId, userTaskId, cityId } = this.urlParams;
      try {
        const res = await insertSkuCategoryTask({
          jobId,
          userTaskId,
          companyId: this.companyId, // DD的companyId
          dataType: DATA_TYPE, // SKU品类
          cityId,
          skuCategoryList,
        });
        this.loading = false;
        if (res) {
          this.loading = false;
          let msg = "恭喜您提交成功！";
          let confirmButtonText = "完成关闭";
          if (res.code !== 0) {
            msg = res.msg || "抱歉提交失败！";
            confirmButtonText = "我再试试";
          }
          await Dialog["alert"]({
            message: msg,
            confirmButtonText,
          });
          if (res.code === 0) {
            window.close();
            this.isClose = false;
          } else {
            this.showConfirm = false;
          }
        }
      } catch {
        Toast.fail("任务提交异常！");
      }
    },
    addCate(index) {
      const newCate = { categoryName: "" };
      this.categories.splice(index + 1, 0, newCate);
    },
    delCate(index) {
      if (this.categories.length === 1) return;
      this.categories = this.categories.filter((item, i) => i !== index);
    },
    clickFeedback() {
      this.$router.push({ path: '/abnormalFeedback'+ location.search});
    },
  },
};
</script>

<style lang="less" scoped>
.task-name {
  padding: 0 20px;
  font-size: 16px;
  text-align: center;
}
.task-end-time {
  font-size: 14px;
}
.dialog-content-table {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  table {
    width: 80%;
    border-collapse: collapse;
    th {
      background: #f2f6fc;
    }
    tr {
      text-align: center;
    }
  }
}
.category-task {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-size: 16px;
  .skuTaskUpload {
    width: calc(100% - 30px);
    padding: 15px;
    margin-top: 25px;
    font-size: 14px;
    background-color: #e4e7ed;
    border-radius: 20px;
    .skuTaskUploadFile {
      .toolTip {
        display: inline-block;
        margin-bottom: 20px;
      }
    }
  }
  .category {
    background: #fff;
    table {
      width: 100%;
      border-collapse: collapse;
      border-color: #c0c4cc;
      th {
        background: #eee;
      }
      .order {
        width: 15%;
        text-align: center;
      }
      .cate-input {
        text-align: center;
        input {
          width: 80%;
        }
      }
      .operation {
        width: 35%;
        .operation-box {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .van-button--mini {
          width: 45%;
        }
      }
    }
  }
  .submit {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20%;
    .skuTaskSubmit {
      width: 60%;
      color: #fff;
      background: #f56c6c;
      border-color: #f56c6c;
      border-radius: 16px;
    }
    .feedback {
      padding-top: 10px;
      font-size: 14px;
      text-align: center;
      .linkText{
        font-weight: bold;
        color: #3e7bf3
      }
    }
  }
}
</style>
